import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const TeamPage = ({ data }) => {
  return (
    <Layout>
      <PageHeader 
        title="Company Culture" 
        sizes={data.headerImage.childImageSharp.fluid} />
        
      <div className="l-primary">
        <nav>
          <h3>Careers</h3>
          <ul>
            <li>
              <Link to={'/careers/open-positions'}>Open Positions <span className="label">{data.allJobs.totalCount}</span></Link>
              {data.allJobs.edges.length > 0 && (
                <ul>
                  {data.allJobs.edges.map(job => (
                    <li><Link to={job.node.fields.slug}>{job.node.frontmatter.position}</Link></li>
                  ))}
                </ul>
              )}
            </li>
            <li><Link to={'/careers/company-culture'}>Company Culture</Link></li>
            <li><Link to={'/careers/core-values'}>Core Values</Link></li>
          </ul> 
        </nav>

        <section>
          <h2>Core Values <span style={{font: "inherit", color: "#555"}}>– HF GROWS</span></h2>
          <p>All Howell Farms employees are required to uphold the farm's Core Values, which are the principles by which we choose to pursue success:</p>
          <ul>
            <li><em>Honor learning</em> and initiative.</li>
            <li><em>Forge unity</em> through cooperation, humility, and mentorship.</li>
            <li><em>Give generously</em> and serve others.</li>
            <li><em>Require honesty</em> and accountability.</li>
            <li><em>Overcome adversity</em> with perseverance, thoughtfulness, and our goals in mind.</li>
            <li><em>Win respect</em> through consistent positive attitude, commitment, and pride in our work.</li>
            <li><em>Serve faithfully</em> the farm, each other, and the land entrusted to our care.</li>
          </ul>
        </section>
        
        <aside>
          <h3>We're Hiring!</h3>
          <p>Browse our open positions and apply today.</p>
          <Link to={'/careers/open-positions'} className="buttonLink--small">Open Positions</Link>
        </aside>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allJobs: allMarkdownRemark(
      filter: { 
        fields: { 
          type: { eq: "jobs"}
        }
      }, 
      sort: {fields: [frontmatter___position], order: ASC}
    ) {
      totalCount
      edges {
        node {
          fields {
            type
            slug
          }
          html
          excerpt
          frontmatter {
            position
            status
            salary
            reports_to
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "farm-team-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default TeamPage